import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Loader, BackDropLoader, FallbackLoader } from './Components/Common/Loader';
import UserProtectedRoutes from './Components/Common/UserProtectedRoutes';
import AdminProtectedRoutes from './Components/Common/AdminProtectedRoutes';
import CreateBlog from './Components/Admin/CreateBlog';
import BlogList from './Components/Admin/BlogList';
import ResearchCardPage from './Components/Common/ResearchCardPage';


const Login = lazy(() => import('./Components/User/Login'));
const Signup = lazy(() => import('./Components/User/Signup'));
const ResetPassword = lazy(() => import('./Components/User/ResetPassword'));
const ChangePasword = lazy(() => import('./Components/User/ChangePasword'));
const Layout = lazy(() => import('./Components/Layout/Layout'));
const Dashboard = lazy(() => import('./Components/Dashboard/Dashboard'));
const DetailPage = lazy(() => import('./Components/Dashboard/DetailPage'));
const Trades = lazy(() => import('./Components/Trades/Trades'));
const TradesDetails = lazy(() => import('./Components/Trades/TradesDetails'));
const Sectors = lazy(() => import('./Components/Sectors/Sectors'));
const SectorsDetails = lazy(() => import('./Components/Sectors/SectorDetails'))
const Companies = lazy(() => import('./Components/Companies/Companies'));
const CompanyDetails = lazy(() => import('./Components/Companies/CompanyDetails'));
const Watchlist = lazy(() => import('./Components/Watchlist/Watchlist'));
const Support = lazy(() => import('./Components/Support/Support'));
const Research = lazy(() => import('./Components/Research/Research'));
const AdvancedMetrics = lazy(() => import('./Components/AdvancedMetrics/AdvancedMetrics'));
const Dataroma = lazy(() => import('./Components/AdvancedMetrics/dataroma/Dataroma'));
const EarningPressure = lazy(() => import('./Components/AdvancedMetrics/earningPressure/EarningPressure'));
const UserList = lazy(() => import('./Components/Admin/UserList'));
const UserCreateForm = lazy(() => import('./Components/Admin/UserCreateForm'));
const UserInfo = lazy(() => import('./Components/Admin/UserInfo'));
const Top10EURTrades = lazy(() => import('./Components/Trades/Top10EURTrades'));
const TradingViewWidget = lazy(() => import('./Components/AdvancedMetrics/TradingViewWidget'));
const RetailSentiment = lazy(() => import('./Components/AdvancedMetrics/RetailSentiment'));

const App = () => {

  const { showLoader, showBackDropLoader } = useSelector(state => state.userStore);

  return (
    <>
      {showLoader && <Loader show={showLoader} />}
      {showBackDropLoader && <BackDropLoader show={showBackDropLoader} />}

      <BrowserRouter>
        <Suspense fallback={<FallbackLoader />}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/changePassword/:resetToken" element={<ChangePasword />} />

            <Route element={<UserProtectedRoutes />}>
              <Route path="/" element={<Layout />}>
                <Route index element={<Dashboard />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="detailpage" element={<DetailPage />} />
                <Route path="userInfo" element={<UserInfo />} />
                <Route path="trades/:tradeId" element={<TradesDetails />} />
                <Route path="trades" element={<Trades />} />
                <Route path="sectors/:sectorName" element={<SectorsDetails />} />
                <Route path="sectors" element={<Sectors />} />
                <Route path="companies/:companyId" element={<CompanyDetails />} />
                <Route path="companies" element={<Companies />} />
                <Route path="watchlist" element={<Watchlist />} />
                <Route path="watchlist" element={<Watchlist />} />
                <Route path="support" element={<Support />} />
                <Route path="research/:blogId" element={<ResearchCardPage />} />
                <Route path="research" element={<Research />} />
                <Route path="top10eurtrades" element={<Top10EURTrades />} />

                <Route path="advancedmetrics">
                  <Route path="gptPrompt" element={<AdvancedMetrics />} />
                  <Route path="tradingViewWidget" element={<TradingViewWidget />} />
                  <Route path="retail-sentiment" element={<RetailSentiment />} />
                  <Route path="dataroma" element={<Dataroma />} />
                  <Route path="earning-pressure" element={<EarningPressure />} />
                </Route>

                {/* Admin Protected Routes */}
                <Route element={<AdminProtectedRoutes />}>
                  <Route path="admin">
                    <Route path="users" element={<UserList />} />
                    <Route path="users/create" element={<UserCreateForm />} />
                    <Route path="users/:userId" element={<UserInfo />} />
                    <Route path="bloglist" element={<BlogList />} />
                    <Route path="bloglist/create" element={<CreateBlog />} />
                    <Route path="bloglist/:blogId" element={<CreateBlog />} />
                  </Route>
                </Route>

              </Route>
            </Route>

            {/* <Route path="*" element={<PageNotFound />} /> */}

          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  )
}

export default App;
